* {
    font-family: 'Helvetica Neue', arial, sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

.light-theme {
    --primary-color: #542eff;
    --primary-color-surface-1: #f6f0ff;
    --primary-color-intact: #542eff;
    --color-on-primary: #ffffff;
    --primary-container-color: #e4dfff;
    --color-on-primary-container: #170065;
    --secondary-color: #8200f4;
    --color-on-secondary: #ffffff;
    --secondary-container-color: #eddcff;
    --color-on-secondary-container: #290055;
    --text-gradient: linear-gradient(81.84deg, #692eff -9.4%, #9d2eff 51.57%);
    --info-color: #7b5266;
    --color-on-info: #ffffff;
    --info-container-color: #ffd8e8;
    --color-on-info-container: #301121;
    --secondary-info-color: #ffd9df;
    --color-on-secondary-info: #321019;
    --snackbar-color: #5f5c71;
    --color-on-snackbar: #ffffff;
    --border-color: #787680;
    --grey-color: #6c7476;
    --error-color: #ba1a1a;
    --color-on-error: #ffffff;
    --error-container-color: #ffdad6;
    --color-on-error-container: #410002;
    --success-color: #1aba1a;
    --color-on-success: #ffffff;
    --success-container: #daffd6;
    --color-on-success-container: #004102;
    --surface-color: rgb(255, 251, 255);
    --surface-color-2: rgb(248,244,248);
    --color-on-surface: rgb(28, 27, 31);
    --light-surface-color: rgb(229, 225, 236);
    --color-on-light-surface: rgb(71, 70, 79);
    --neutral-surface-color: rgb(243, 239, 244);
    --color-on-neutral-surface: rgb(60, 59, 63);
    --radius-1: 6px;
    --radius-2: 8px;
    --radius-3: 10px;
    --box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1);
    --modal-background-color: rgba(255, 255, 255, 0.7);
    --disabled-button-color: #c9c4ca;
    --color-on-disabled-button: #535256;
}

.dark-theme {
    --primary-color: #c7bfff;
    --color-on-primary: #2a009f;
    --primary-color-surface-1: #2d2b33;
    --primary-container-color: #3d00dd;
    --color-on-primary-container: #e4dfff;
    --secondary-color: #d8b9ff;
    --color-on-secondary: #450086;
    --secondary-container-color: #6200bc;
    --color-on-secondary-container: #eddcff;
    --info-color: #ecb8cf;
    --color-on-info: #482537;
    --info-container-color: #613b4e;
    --color-on-info-container: #ffd8e8;
    --secondary-info-color: #653b43;
    --color-on-secondary-info: #ffd9df;
    --snackbar-color: #c8c3dc;
    --color-on-snackbar: #302e41;
    --border-color: rgb(146, 143, 153);
    --grey-color: rgb(160, 157, 167);
    --error-color: rgb(255, 180, 171);
    --color-on-error: rgb(105, 0, 5);
    --error-container-color: #93000a;
    --color-on-error-container: #ffdad6;
    --success-color: #b4ffab;
    --color-on-success: #006905;
    --success-container: #00930a;
    --color-on-success-container: #daffd6;
    --surface-color: rgb(39, 38, 42);
    --surface-color-2: rgb(35,34,38);
    --color-on-surface: #d2ced3;
    --light-surface-color: rgb(61, 60, 69);
    --color-on-light-surface: rgb(191, 187, 198);
    --neutral-surface-color: #1c1b1f;
    --color-on-neutral-surface: #e5e1e6;
    --modal-background-color: rgba(0, 0, 0, 0.7);
    --disabled-button-color: #3c3a43;
    --color-on-disabled-button: #928f99;
}


body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
}

@media (prefers-color-scheme:dark) {
    body::after {
        content: 'dark';
        display: none;
    }

    body {
        background-color: #1c1b1f;
    }
}

@media (pointer: fine) {

    button:hover,
    a:hover {
        cursor: pointer;
    }
}

button {
    font-family: inherit;
    font-weight: 550;
}

button:disabled {
    background-color: var(--light-surface-color);
    color: var(--color-on-light-surface);
    cursor: not-allowed;
    background-color: var(--disabled-button-color);
    color: var(--color-on-disabled-button);
    opacity: 0.5;
}

button:disabled svg {
    fill: var(--color-on-disabled-button);
    opacity: 0.5;
}

.server-message,
.client-message {
    padding: 16px;
    border-radius: 20px;
    font-size: 16px;
    word-wrap: break-word;
    line-height: 1.5;
}

.server-message {
    background-color: var(--surface-color);
    color: var(--color-on-surface);
    margin-right: 5%;
    margin-top: 10px;
}

.client-message {
    background-color: var(--primary-color);
    color: var(--color-on-primary);
    margin-left: 5%;
    margin-top: 10px;
}

.time {
    text-align: center;
    font-size: 14px;
    color: var(--grey-color);
    margin-top: 10px;
}


.value-of-key{
    color: var(--grey-color);
}

.key-value-container{
    background-color: var(--primary-color-surface-1);
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 6px;
}

.key-value-container:last-child{
    margin-bottom: 0;
}